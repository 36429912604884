// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import LazyLoad from "vanilla-lazyload";

require('@rails/ujs').start();
require('channels');
import './modules/collapseToggle';
import './modules/notification';
import './modules/flash';
import './modules/prevent-transition-onload';
import './modules/contentToggle';
import './modules/google-track';

var myLazyLoad = new LazyLoad();
myLazyLoad.update();

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)
