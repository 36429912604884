const $contentToggles = document.querySelectorAll('[data-toggle="content"]');
for (const toggle of $contentToggles) {
  toggle.addEventListener("click", contentSwitch);
}

function contentSwitch(event) {
    const $showTarget = document.querySelector(this.dataset.showTarget);
    const $hideTarget = document.querySelector(this.dataset.hideTarget);
    const $siblingTarget = document.querySelector(this.dataset.sibling);

    this.classList.add('active');
    $showTarget.classList.add('active');
    $hideTarget.classList.remove('active');
    $siblingTarget.classList.remove('active');
}
