export default {
  enable() {
    document.documentElement.classList.add("scroll-lock");
    document.body.classList.add("scroll-lock");
  },
  disable() {
    document.documentElement.classList.remove("scroll-lock");
    document.body.classList.remove("scroll-lock");
  },
  toggle() {
    document.documentElement.classList.toggle("scroll-lock");
    document.body.classList.toggle("scroll-lock");
  }
};
