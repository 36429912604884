import scrollLock from './scroll-lock';

const $collaspseToggles = document.querySelectorAll('[data-toggle="collapse"]');
for (const toggle of $collaspseToggles) {
  toggle.addEventListener("click", collapse);
}

function collapse(event) {
  const $target = document.querySelector(this.dataset.target);
  const $overlayBg = document.querySelector(this.dataset.overlay);

  this.classList.toggle("active");
  $target.classList.toggle("active");

  if ($target.dataset.scrollLock === "true") {
      scrollLock.toggle();
  }

  if ($target.dataset.state === "close") {
    $overlayBg ? $overlayBg.classList.add("active") : "";
    $target.dataset.state = "open";
  } else {
    $overlayBg ? $overlayBg.classList.remove("active") : "";
    $target.dataset.state = "close";
  }
}
