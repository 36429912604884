import axios from "axios";

// For testing: 設定使用者在很久之前才到訪過
// localStorage.setItem("notificationTimeStamp", "2020-03-01T17:02:17Z");

const $notification = document.getElementById("notification");
const $notificationBtn = document.getElementById("notificationBtn");
const $notificationList = document.getElementById("notificationList");
const $notificationCount = document.getElementById("notificationCount");
const $notificationToggleProxy = document.getElementById("notificationToggleProxy");

if ($notification) {
    let recentNews = [];
    loadNews();

    $notificationBtn.addEventListener("click", function(event) {
    const dataString = new Date().toISOString();
    localStorage.setItem("notificationTimeStamp", dataString);

    updateNewsCount();
    toggleNotificationProxy();
    });

    $notificationToggleProxy.addEventListener("click", function(event) {
    $notificationBtn.click();
    });

    function toggleNotificationProxy() {
        const targetPanel = document.querySelector($notificationBtn.dataset.target);
        if (targetPanel.dataset.state === 'close') {
            $notificationToggleProxy.classList.remove('active');
        } else {
            $notificationToggleProxy.classList.add('active');
        }
    }

    async function loadNews() {
      const now = new Date();
      let data = [];

      try {
        let res = await axios.get("/api/posts.json");
        data = res.data;
      } catch (err) {
        console.error(err);
      }

      recentNews = data.filter((el) => {
        const publishedDate = new Date(el.publishAt);
        const diffInTime = now.getTime() - publishedDate.getTime();
        const diffInDays = diffInTime / (1000 * 3600 * 24);

        if (diffInDays <= 3) {
          return el;
        }
      });

      recentNews.forEach((el) => {
        const publishedDate = new Date(el.publishAt);
        const dateString = `${publishedDate.getFullYear()}.${publishedDate.getMonth() +
          1}.${publishedDate.getDate()}`;
        renderNewsList($notificationList, dateString, el.title, el.id);
      });

      updateNewsCount();
    }

    function updateNewsCount() {
      const timeStamp = localStorage.getItem("notificationTimeStamp");
      let count = 0;
      if (timeStamp) {
        const lastTimeStamp = new Date(timeStamp);
        recentNews.forEach((el) => {
          const publishedDate = new Date(el.publishAt);

          if (publishedDate > lastTimeStamp) {
            count++;
          }
        });
      }
      $notificationCount.textContent = count;
      count === 0
        ? $notification.classList.add("disable")
        : $notification.classList.remove("disable");
    }

    function renderNewsList(target, date, title, id) {
      const markup = `
                <li class="list-item">
                    <a href="/news/${id}" class="list-link">
                        <span class="date">${date}</span>
                        <span class="title">${title}</span>
                    </a>
                </li>
            `;
      target.insertAdjacentHTML("beforeend", markup);
    }
}


