// example:
//
// ``` html
// <button data-gtag='["category", "action", "label"]'></button>
// ```
//

const $elems = document.querySelectorAll('[data-gtag]')

for (let i = 0; i < $elems.length; i++) {
  const $elem = $elems[i]

  $elem.addEventListener('click', (event) => {
    const gtagData = event.currentTarget.getAttribute('data-gtag')
    const params = JSON.parse(gtagData)

    if (params) {
      const category = params[0]
      const action = params[1]
      const label = params[2]

      window.gtag && window.gtag('event', action, {
        event_category: category,
        event_label: label
      })
    }
  })
}
